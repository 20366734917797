import { NgModule } from '@angular/core';
import { RolePipe } from './pipes/role.pipe';
import { DateComponent } from './components/table/date/date.component';
import { CommonModule } from '@angular/common';
import { BooleanComponent } from './components/table/boolean/boolean.component';
import { RoleComponent } from './components/table/role/role.component';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { OrderStatusComponent } from './components/table/order-status/order-status.component';
import { OrderActionsComponent } from './components/table/order-actions/order-actions/order-actions.component';
import {
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbIconModule,
  NbInputModule,
  NbToastrModule
} from '@nebular/theme';
import { InstallationTypePipe } from './pipes/installation-type.pipe';
import { InstallationTypeComponent } from './components/table/installation-type/installation-type.component';
import { InstallationActionsComponent } from './components/table/installation-actions/installation-actions/installation-actions.component';
import { InstallationStatusPipe } from './pipes/installation-status.pipe';
import { InstallationStatusComponent } from './components/table/installation-status/installation-status.component';
import { InstallationDatePickerComponent } from './components/installation-date-picker/installation-date-picker.component';
import { DateWithSlaComponent } from './components/table/date-with-sla/date-with-sla.component';
import { SlaComponent } from './components/table/sla/sla.component';
import { NotesComponent } from './components/table/notes/notes.component';
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

const TABLE_FIELDS = [
  BooleanComponent,
  DateComponent,
  DateWithSlaComponent,
  InstallationActionsComponent,
  InstallationStatusComponent,
  InstallationTypeComponent,
  NotesComponent,
  OrderActionsComponent,
  OrderStatusComponent,
  RoleComponent,
  SlaComponent,
];

const COMPONENTS = [
  ...TABLE_FIELDS,
  ConfirmationDialogComponent,
  InstallationDatePickerComponent,
];

const CUSTOM_PIPES = [
  InstallationStatusPipe,
  InstallationTypePipe,
  OrderStatusPipe,
  RolePipe,
];

const MODULES = [
  CommonModule,
  FormsModule,
  NbIconModule,
  NbInputModule,
  NbToastrModule,
];

@NgModule({
  imports: [
    ...MODULES,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
  ],
  declarations: [
    ...COMPONENTS,
    ...CUSTOM_PIPES,
  ],
  providers: [
    ...CUSTOM_PIPES,
  ],
  exports: [
    ...COMPONENTS,
    ...CUSTOM_PIPES,
    ...MODULES,
  ],
})
export class SharedModule {}
