import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { InstallationStatus, Order, OrderStatus } from '../models/order.model';
import { endpoint } from '../utils/endpoint';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';

export type OrderViewModel = Order & {
  checked: false;
};

export interface OrderUpdate {
  id: number;
  status?: OrderStatus;
  notes?: string;
  installation?: InstallationUpdate;
}

export interface InstallationUpdate {
  status?: InstallationStatus;
  date?: string;
  notes?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderStore {
  private readonly _orders$ = new BehaviorSubject<Order[]>(undefined);
  readonly orders$ = this._orders$.asObservable();

  constructor(private http: HttpClient) {}

  load(): Observable<Order[]> {
    const url = endpoint('/orders');
    return this.http.get<Order[]>(url, { withCredentials: true })
      .pipe(
        tap(orders => this._orders$.next(orders)),
      );
  }

  patchOrders(orders: OrderUpdate[]): Observable<Order[]> {
    const url = endpoint('/orders');
    return this.http.patch<void>(url, orders, { withCredentials: true })
      .pipe(
        switchMap(() => this.load()),
      );
  }
}
