import { Pipe, PipeTransform } from '@angular/core';
import { Role } from '../models/role.model';

@Pipe({
  name: 'role',
})
export class RolePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    switch (value) {
      case Role.admin:
        return 'Admin';
      case Role.bank_employee:
        return 'Pracownik PKO';
      case Role.policy_owner:
        return 'Klient PKO';
      case Role.super_admin:
        return 'SuperAdmin';
      case Role.backoffice:
        return 'Backoffice';
      default:
        throw new Error(`Unknown role: ${value}`);
    }
  }
}
