import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { OrderStore } from '../../stores/order.store';
import { Order } from '../../models/order.model';

@Component({
  selector: 'ngx-installation-date-picker',
  templateUrl: './installation-date-picker.component.html',
  styleUrls: ['./installation-date-picker.component.scss'],
})
export class InstallationDatePickerComponent implements OnInit {

  date: any;
  order: Order;

  constructor(
    public dialogRef: NbDialogRef<any>,
    private orderStore: OrderStore,
  ) { }

  ngOnInit(): void {

  }

  onDateChange(date: Date) {
    // below "nice and simple" way to convert JS date to UTC format xD
    this.date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString();
  }

  updateInstallationDate() {
    this.dialogRef.close(this.date);
  }

  cancel() {
    this.dialogRef.close();
  }
}
