<nb-card>
  <nb-card-header>Wybierz termin instalacji</nb-card-header>
  <nb-card-body>
    <input nbInput placeholder="Wybierz datę" [nbDatepicker]="dateTimePicker">
    <nb-datepicker #dateTimePicker (dateChange)="onDateChange($event)"></nb-datepicker>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="cancel()">Anuluj</button>
    <button nbButton (click)="updateInstallationDate()" status="success">Ok</button>
  </nb-card-footer>
</nb-card>
