import { Pipe, PipeTransform } from '@angular/core';
import { InstallationType } from '../models/order.model';

@Pipe({
  name: 'installation_type',
  pure: true,
})
export class InstallationTypePipe implements PipeTransform {
  transform(value: InstallationType, ...args: any[]): any {
    switch (value) {
      case InstallationType.onsite:
        return 'Instalator';
      case InstallationType.remote:
        return 'Zdalna';
      case InstallationType.self:
        return 'Samodzielna';
      default:
        throw new Error(`Unknown installation type: ${value}`);
    }
  }
}
