<button *ngIf="authService.isBackoffice && rowData.status === orderStatus.paid" nbButton [attr.data-status]="orderStatus.in_progress" size="tiny" (click)="updateStatus(orderStatus.in_progress)">
  Do realizacji ›
</button>
<button *ngIf="authService.isBackoffice && rowData.status === orderStatus.in_progress" nbButton [attr.data-status]="orderStatus.delivery_shipped" size="tiny" (click)="updateStatus(orderStatus.delivery_shipped)">
  Do wysyłki ›
</button>
<button *ngIf="authService.isAdmin && rowData.status === orderStatus.delivery_shipped" nbButton [attr.data-status]="orderStatus.delivery_completed" size="tiny" (click)="updateStatus(orderStatus.delivery_completed)">
  Odebrano ›
</button>
<button *ngIf="authService.isAdmin && rowData.status === orderStatus.delivery_completed && rowData.installation?.date" nbButton [attr.data-status]="orderStatus.completed" size="tiny" (click)="updateStatus(orderStatus.completed)">
  Zakończ ›
</button>
