import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order } from '../models/order.model';
import { endpoint } from '../utils/endpoint';
import { HttpClient } from '@angular/common/http';
import { switchMap, tap } from 'rxjs/operators';
import { OrderUpdate } from './order.store';

@Injectable({
  providedIn: 'root',
})
export class InstallationStore {
  private readonly _installations$ = new BehaviorSubject<Order[]>(undefined);
  readonly installations$ = this._installations$.asObservable();

  constructor(private http: HttpClient) {}

  load(): Observable<Order[]> {
    const url = endpoint('/orders?installationMethod=onsite,remote');
    return this.http.get<Order[]>(url, { withCredentials: true })
      .pipe(
        tap(orders => this._installations$.next(orders)),
      );
  }

  patch(orders: OrderUpdate[]): Observable<Order[]> {
    const url = endpoint('/orders');
    return this.http.patch<void>(url, orders, { withCredentials: true })
      .pipe(
        switchMap(() => this.load()),
      );
  }
}
