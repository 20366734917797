export function toUtc(date: Date): Date {
  // const utc = Date.UTC(
  //   date.getUTCFullYear(),
  //   date.getUTCMonth(),
  //   date.getUTCDate(),
  //   date.getUTCHours(),
  //   date.getUTCMinutes(),
  //   date.getUTCSeconds());
  // return new Date(utc);

  return new Date(date.toUTCString().substr(0, 25));
}
