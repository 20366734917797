<ng-container *ngIf="authService.isAdmin && isOrderDeliveryShipped(rowData)">

  <button *ngIf="rowData.installation.status === status.paid" nbButton [attr.data-status]="status.scheduled" size="tiny" (click)="selectInstallationDate()">
    Ustaw termin ›
  </button>
  <button *ngIf="rowData.installation.status === status.scheduled" nbButton [attr.data-status]="status.in_progress" size="tiny" (click)="updateStatus(status.in_progress)">
    Do realizacji ›
  </button>
  <button *ngIf="rowData.installation.status === status.in_progress" nbButton [attr.data-status]="status.completed" size="tiny" (click)="updateStatus(status.completed)">
    Zakończ ›
  </button>
  <button *ngIf="rowData.installation.status === status.paid || rowData.installation.status === status.scheduled" nbButton [attr.data-status]="status.failed_contact_attempt" size="tiny" (click)="updateStatus(status.failed_contact_attempt)">
    Nieudany kontakt
  </button>
  <button *ngIf="rowData.installation.status === status.cancelled || rowData.installation.status === status.failed_contact_attempt" nbButton [attr.data-status]="status.scheduled" size="tiny" (click)="selectInstallationDate()">
    Ponowny termin ›
  </button>
  <button *ngIf="rowData.installation.status !== status.cancelled && rowData.installation.status !== status.completed && rowData.installation.status" nbButton [attr.data-status]="status.cancelled" size="tiny" (click)="updateStatus(status.cancelled)">
    Anuluj
  </button>

</ng-container>
