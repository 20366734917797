import { toUtc } from './to-utc';

export function calculateSla(fromDate: string): [number, number] {
  const from = Date.parse(fromDate);
  const now = Date.parse(new Date().toISOString());

  const diff = toUtc(new Date(now - from));

  return [23 - diff.getHours(), 59 - diff.getMinutes()];
}
