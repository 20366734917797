import { Component, Input, OnInit } from '@angular/core';
import { InstallationStatus, Order, OrderStatus } from '../../../../models/order.model';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { InstallationDatePickerComponent } from '../../../installation-date-picker/installation-date-picker.component';
import { NEVER, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { InstallationStore } from '../../../../stores/installation.store';
import { AuthService } from '../../../../auth/services/auth.service';
import { InstallationStatusPipe } from '../../../../pipes/installation-status.pipe';
import { NotificationMessage } from '../../../../models/notification-message.model';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { STATUS_CONFIRMATION_DIALOG_CONTENT } from '../../../../models/status-confirmation-dialog-content';

@Component({
  selector: 'ngx-installation-actions',
  templateUrl: './installation-actions.component.html',
  styleUrls: ['./installation-actions.component.scss'],
})
export class InstallationActionsComponent implements OnInit {

  @Input() rowData: Order;

  readonly status = InstallationStatus;

  private destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private installationStore: InstallationStore,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private installationStatusPipe: InstallationStatusPipe,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  isOrderDeliveryShipped(order: Order): boolean {
    return order.status === OrderStatus.delivery_shipped
      || order.status === OrderStatus.delivery_completed
      || order.status === OrderStatus.completed;
  }

  selectInstallationDate() {
    const dialog = this.dialogService.open(InstallationDatePickerComponent, {
      context: {
        order: this.rowData,
      },
    });

    dialog
      .onClose
      .pipe(
        filter(x => !!x),
        switchMap(date => this.confirmUpdate(InstallationStatus.scheduled).pipe(map(ok => [ok, date]))),
        switchMap(([ok, date]) => ok ? of(date) : NEVER),
        switchMap(date => this.installationStore.patch([{ id: this.rowData.id, installation: { date } }])),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.showNotification(InstallationStatus.scheduled);
      });
  }

  updateStatus(status: InstallationStatus) {
    this.confirmUpdate(status)
      .pipe(
        switchMap(ok => ok ? of(true) : NEVER),
        switchMap(() => this.installationStore.patch([{ id: this.rowData.id, installation: { status } }])),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.showNotification(status);
      });
  }

  private confirmUpdate(status: InstallationStatus): Observable<boolean> {
    const notification = this.getNotificationMessage(status);

    const dialog = this.dialogService.open(ConfirmationDialogComponent, {
      context: {
        title: 'Zmiana statusu instalacji',
        content: STATUS_CONFIRMATION_DIALOG_CONTENT,
        statusChange: {
          old: {
            status: this.rowData.installation.status,
            name: this.installationStatusPipe.transform(this.rowData.installation.status),
          },
          new: {
            status: status,
            name: this.installationStatusPipe.transform(status),
          },
          messages: notification ? [notification.name] : [],
        },
      },
    });

    return dialog.onClose;
  }

  private showNotification(status: InstallationStatus): void {
    const message = this.getNotificationMessage(status);
    if (!message) {
      return;
    }
    this.toastrService.show(message.text, 'Wysłano wiadomość');
  }

  private getNotificationMessage(status: InstallationStatus): NotificationMessage {
    switch (status) {
      case InstallationStatus.scheduled:
        return {
          name: 'Potwierdzenie do klienta terminu instalacji',
          text: 'Wysłano potwierdzenie terminu instalacji.',
        };
      case InstallationStatus.failed_contact_attempt:
        return {
          name: 'Powiadomienie do klienta o nieudanej próbie kontaktu',
          text: 'Wysłano powiadomienie o nieudanej próbie kontaktu.',
        };
      case InstallationStatus.cancelled:
        return {
          name: 'Powiadomienie do klienta o anulowaniu instalacji z przyczyn losowych',
          text: 'Wysłano powiadomienie o anulowaniu instalacji z przyczyn losowych.',
        };
      case InstallationStatus.completed:
        return {
          name: 'Prośba do klienta o wypełnienie ankiety',
          text: 'Wysłano prośbę o wypełnienie ankiety.',
        };
      default:
        return undefined;
    }
  }
}
