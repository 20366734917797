import { Injectable } from '@angular/core';
import { BehaviorSubject, NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { User } from '../../models/user.model';
import { environment } from '../../../../environments/environment';

export interface SignInResult {
  readonly ok: boolean;
  readonly user?: User;
  readonly error?: SignInError;
}

export enum SignInError {
  unauthorized = 'unauthorized',
}

export interface UserActivation {
  readonly email: string;
  readonly activationCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$ = new BehaviorSubject<User>(undefined);
  readonly user$ = this._user$.asObservable();
  get user() { return this._user$.getValue(); }

  get isBackoffice() {
    return this.user.role === 'backoffice';
  }

  get isAdmin() {
    return this.user.role === 'admin' || this.user.role === 'super_admin';
  }

  constructor(private readonly http: HttpClient) {}

  // signInPolicyNo(form: PolicyNoForm): Observable<User> {
  //   const url = `${environment.apiRoot}/insurances/verification`;
  //   return this.http.post(url, form, { withCredentials: true }).pipe(switchMap(() => this.fetchUser()));
  // }
  //
  // signUp(signUpForm: SignUpForm, role: 'bank_employee'): Observable<User> {
  //   const url = `${environment.apiRoot}/users/sign-up`;
  //   return this.http.post<User>(url, { ...signUpForm, role }, { withCredentials: true });
  // }
  //
  // activateUser(activation: UserActivation): Observable<void> {
  //   const url = `${environment.apiRoot}/users/activate`;
  //   return this.http.post<void>(url, activation, { withCredentials: true });
  // }
  //
  // signIn(email: string, password: string): Observable<SignInResult> {
  //   const url = `${environment.apiRoot}/users/sign-in`;
  //   const body = { name: email, password };
  //   this._user$.next(undefined);
  //   return this.http.post<string>(url, body, { withCredentials: true, observe: 'response' })
  //     .pipe(
  //       switchMap(response => {
  //         if (response.status === 204) {
  //           return this.fetchUser().pipe(map(user => ({ ok: true, user })));
  //         }
  //         return throwError(response);
  //       }),
  //       catchError((error: HttpErrorResponse) => {
  //         if (error.status === 401) {
  //           return of({ ok: false, error: SignInError.unauthorized });
  //         }
  //         return throwError(error);
  //       })
  //     );
  // }

  signOut(): Observable<void> {
    const url = `${environment.apiRoot}/users/sign-out`;
    return this.http.post<void>(url, null, { withCredentials: true })
      .pipe(
        tap(() => this._user$.next(undefined)),
      );
  }

  autoSignIn(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    return this.fetchUser();
  }

  private fetchUser(): Observable<User> {
    const url = `${environment.apiRoot}/users/me`;
    return this.http.get<User>(url, { withCredentials: true })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this._user$.next(undefined);
            return of(undefined);
          }
          return throwError(error);
        }),
        switchMap(user => {
          if (user && (user.role === 'bank_employee' || user.role === 'policy_owner')) {
            window.location.href = environment.ecommerceUrl;
            return NEVER;
          }
          return of(user);
        }),
        map(user => {
          this._user$.next(user);
          return user;
        }),
      );
  }
}
