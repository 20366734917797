<nb-card>
  <nb-card-header>
    {{ title }}
  </nb-card-header>
  <nb-card-body>
    <p *ngFor="let paragraph of content">
      {{ paragraph }}
    </p>

    <ng-container *ngIf="statusChange">
      <div style="margin-bottom: 1rem">
        <span [attr.data-status]="statusChange.old.status">{{ statusChange.old.name }}</span>
        →
        <span [attr.data-status]="statusChange.new.status">{{ statusChange.new.name }}</span>
      </div>

      <p *ngFor="let message of statusChange.messages" style="display: flex;">
        <nb-icon style="margin-right: 5px;" icon="email-outline"></nb-icon> {{ message }}
      </p>
    </ng-container>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="cancel()">{{ labels.cancel || 'Anuluj' }}</button>
    <button nbButton (click)="ok()" status="success" style="margin-left: 10px;">{{ labels.ok || 'Ok' }}</button>
  </nb-card-footer>
</nb-card>
