import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-installation-status',
  templateUrl: './installation-status.component.html',
  styleUrls: ['./installation-status.component.scss'],
})
export class InstallationStatusComponent implements OnInit {

  @Input() value: any;

  constructor() { }

  ngOnInit(): void {
  }

}
