import { Component, Input, OnInit } from '@angular/core';
import { OrderStore } from '../../../stores/order.store';
import { NbToastrService } from '@nebular/theme';
import { Observable } from 'rxjs';

export interface NotesInput {
  initialValue: string;
  save: (id: number, notes: string) => Observable<any>;
}

@Component({
  selector: 'ngx-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {

  enableSave = false;

  get text() { return this._text; }
  set text(value: string) {
    this._text = value;
    this.enableSave = true;
  }
  private _text = '';

  @Input() value: NotesInput;
  rowData: any;

  constructor(
    private orderStore: OrderStore,
    private toastrService: NbToastrService,
  ) {}

  ngOnInit(): void {
    this.resetValue();
  }

  saveNotes() {
    this.enableSave = false;
    // this.orderStore.patchOrders([{ id: this.rowData.id, [this.value.key]: this.text }]).subscribe(() => {
    //   this.toastrService.show('Notatka zapisana pomyślnie.', 'Zapisano notatkę');
    // });

    this.value.save(this.rowData.id, this.text).subscribe(() => {
      this.toastrService.show('Notatka zapisana pomyślnie.', 'Zapisano notatkę');
    });
  }

  cancel() {
    this.enableSave = false;
    this.resetValue();
  }

  private resetValue() {
    // this._text = this.rowData[this.value.key];
    this._text = this.value.initialValue;
  }
}
