import { Component } from '@angular/core';
import { Role } from '../../../models/role.model';

@Component({
  selector: 'ngx-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss'],
})
export class RoleComponent {
  value: any;
}
