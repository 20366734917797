import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export interface StatusChangeConfirmation {
  old: StatusViewModel;
  new: StatusViewModel;
  messages: string[];
}

export interface StatusViewModel {
  status: string;
  name: string;
}

@Component({
  selector: 'ngx-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {

  title: string;
  content: string[];

  labels = {
    ok: 'Ok',
    cancel: 'Anuluj',
  };

  statusChange: StatusChangeConfirmation;

  constructor(private dialogRef: NbDialogRef<any>) {}

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  ok() {
    this.dialogRef.close(true);
  }
}
