import { Component, OnInit } from '@angular/core';
import { calculateSla } from '../../../utils/calculate-sla';

@Component({
  selector: 'ngx-sla',
  templateUrl: './sla.component.html',
  styleUrls: ['./sla.component.scss'],
})
export class SlaComponent implements OnInit {

  value: any;
  rowData: any;

  slaHours: number;
  slaMinutes: number;

  constructor() { }

  ngOnInit(): void {
    const [hours, minutes] = calculateSla(this.rowData.createdAt);
    this.slaHours = hours;
    this.slaMinutes = minutes;
  }
}
