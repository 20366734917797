import { Component, OnDestroy, OnInit } from '@angular/core';
import { Order, OrderStatus } from '../../../../models/order.model';
import { OrderStore } from '../../../../stores/order.store';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { AuthService } from '../../../../auth/services/auth.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../../confirmation-dialog/confirmation-dialog.component';
import { OrderStatusPipe } from '../../../../pipes/order-status.pipe';
import { STATUS_CONFIRMATION_DIALOG_CONTENT } from '../../../../models/status-confirmation-dialog-content';
import { NotificationMessage } from '../../../../models/notification-message.model';

@Component({
  selector: 'ngx-order-actions',
  templateUrl: './order-actions.component.html',
  styleUrls: ['./order-actions.component.scss'],
})
export class OrderActionsComponent implements OnInit, OnDestroy {

  rowData: Order;

  readonly orderStatus = OrderStatus;

  private readonly destroy$ = new Subject<void>();

  constructor(
    public authService: AuthService,
    private orderStore: OrderStore,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private orderStatusPipe: OrderStatusPipe,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateStatus(status: OrderStatus) {
    const notification = this.getNotificationMessage(status);

    const dialog = this.dialogService.open(ConfirmationDialogComponent, {
      context: {
        title: 'Zmiana statusu zamówienia',
        content: STATUS_CONFIRMATION_DIALOG_CONTENT,
        statusChange: {
          old: { status: this.rowData.status, name: this.orderStatusPipe.transform(this.rowData.status) },
          new: { status: status, name: this.orderStatusPipe.transform(status) },
          messages: notification ? [notification.name] : [],
        },
      },
    });

    dialog.onClose.pipe(takeUntil(this.destroy$)).subscribe(ok => {
      if (!ok) {
        return;
      }
      this.orderStore.patchOrders([{ id: this.rowData.id, status }]).subscribe(() => {
        if (notification) {
          this.toastrService.show(notification.text, 'Wysłano wiadomość');
        }
      });
    });
  }

  private getNotificationMessage(status: OrderStatus): NotificationMessage {
    switch (status) {
      case OrderStatus.in_progress:
        return {
          name: 'Zamówienie na magazyn',
          text: 'Wysłano zamówienie na magazyn.',
        };
      case OrderStatus.delivery_shipped:
        return {
          name: 'Potwierdzenie wysyłki do klienta',
          text: 'Wysłano potwierdzenie wysyłki zamówienia.',
        };
      case OrderStatus.completed:
        return {
          name: 'Zachęta do ponownego zakupu',
          text: 'Wysłano zachętę do ponownego zakupu.',
        };
      default:
        return undefined;
    }
  }
}
