import { OrderItem } from './order-item';

export enum OrderStatus {
  waiting_for_payment = 'waiting_for_payment',
  paid = 'paid',
  payment_cancelled = 'payment_cancelled',
  in_progress = 'in_progress',
  delivery_shipped = 'delivery_shipped',
  delivery_completed = 'delivery_completed',
  stale = 'stale',
  completed = 'completed',
}

export enum InstallationType {
  onsite = 'onsite',
  remote = 'remote',
  self = 'self',
}

export enum InstallationStatus {
  waiting_for_payment = 'waiting_for_payment',
  paid = 'paid',
  payment_cancelled = 'payment_cancelled',
  failed_contact_attempt = 'failed_contact_attempt',
  scheduled = 'scheduled',
  in_progress = 'in_progress',
  completed = 'completed',
  cancelled = 'cancelled',
}

export interface Order {
  readonly id: number;
  readonly createdAt: string;
  readonly status: OrderStatus;
  readonly price: number;
  readonly clientRole: string;
  readonly orderNo: number;
  readonly orderNumber: string;
  readonly notes: string;
  readonly installation: Installation;
  readonly items: OrderItem[];
}

export interface Installation {
  readonly type: string;
  readonly status: InstallationStatus;
  readonly date: string;
  readonly notes: string;
}
