import { Pipe, PipeTransform } from '@angular/core';
import { InstallationStatus, OrderStatus } from '../models/order.model';

@Pipe({
  name: 'installation_status',
  pure: true,
})
export class InstallationStatusPipe implements PipeTransform {
  transform(value: InstallationStatus, ...args: any[]): string {
    switch (value) {
      case InstallationStatus.waiting_for_payment:
        return 'Oczekiwanie na płatność';
      case InstallationStatus.paid:
        return 'Zapłacono';
      case InstallationStatus.payment_cancelled:
        return 'Płatność anulowana';
      case InstallationStatus.failed_contact_attempt:
        return 'Nieudana próba kontaktu';
      case InstallationStatus.scheduled:
        return 'Umówiono';
      case InstallationStatus.in_progress:
        return 'Oczekiwanie na protokół';
      case InstallationStatus.completed:
        return 'Zakończono';
      case InstallationStatus.cancelled:
        return 'Anulowano';
      default:
        throw new Error(`Unknown installation status: ${value}`);
    }
  }
}
