import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent {
  value: any;

  ngOnInit() {
  }
}
